import axios from 'axios';
import ConfigurationManager from './components/ConfigurationManager';
/*import PlaybackManager from './PlaybackManager';*/


export async function getImagePathForPersonna(token, persona) {
    const realToken = token;
    try {
        const instance = axios.create({
            baseURL: ConfigurationManager.getApiUrl(),
            timeout: 15000,
            headers: { 'Authorization': `Bearer ${realToken}` }
        });

        var url = `/chat/GetImagePathFor?&persona=${encodeURIComponent(persona)}`;
        const res = await instance.get(url);

        return res.data;
    } catch (err) {
        console.error(err.message);

        if (err.response != null && err.response.data != null)
            console.log(err.response.data);

        return;
    }
}
export async function newChat(token, persona) {
    const realToken = token;
    try {
        const instance = axios.create({
            baseURL: ConfigurationManager.getApiUrl(),
            timeout: 60000,
            headers: { 'Authorization': `Bearer ${realToken}` }
        });

        var url = `/chat/NewConversation?persona=${encodeURIComponent(persona)}`;
        const res = await instance.get(url);
        
        return res.data;
    } catch (err) {
        console.error(err.message);

        if (err.response != null && err.response.data != null)
            console.log(err.response.data);

        return;
    }
}

export async function deleteChat(token, persona, chatId) {
    const realToken = token;
    try {
        const instance = axios.create({
            baseURL: ConfigurationManager.getApiUrl(),
            timeout: 15000,
            headers: { 'Authorization': `Bearer ${realToken}` }
        });

        var url = `/chat/DeleteChat?persona=${encodeURIComponent(persona)}&chatid=${chatId}`;
        const res = await instance.delete(url);

        return res.data;
    } catch (err) {
        console.error(err.message);

        if (err.response != null && err.response.data != null)
            console.log(err.response.data);

        return;
    }
}

export async function sendMessage(token, persona, chatId, message, messageImages) {
    const realToken = token;
    try {
        const instance = axios.create({
            baseURL: ConfigurationManager.getApiUrl(),
            timeout: 60000,
            headers: { 'Authorization': `Bearer ${realToken}` }
        });

        if (message === null || message === undefined) 
            message = '';

        var reply = await instance.post('/chat/UpdateChat', {
            persona: persona,            
            chatId: chatId,
            input: message, 
            images: messageImages
        }, {
            params: {
                //persona: 'test',
                //sessionId: sessionId,
                //chatId: chatId,
                //input: message
            }
        }); 
        
        return reply.data;
    } catch (err) {
        console.error(err.message);

        if (err.response != null && err.response.data != null)
            console.log(err.response.data);

       return;
    }
}

export async function sendPersonaUpdate(token, personaData) {
    const realToken = token;
    try {
        const instance = axios.create({
            baseURL: ConfigurationManager.getApiUrl(),
            timeout: 30000,
            headers: { 'Authorization': `Bearer ${realToken}` }
        });

        await instance.post('/persona/mypost', personaData);

    } catch (err) {
        console.error(err.message);

        if (err.response != null && err.response.data != null)
            console.log(err.response.data);

        return;
    }
}

export async function getPersonas(token) {
    const realToken = token;

    try {
        const instance = axios.create({
            baseURL: ConfigurationManager.getApiUrl(),
            timeout: 15000,
            headers: { 'Authorization': `Bearer ${realToken}` }
        });

        const res = await instance.get('/persona/AllCurrent');

        return res.data;
    } catch (err) {
        console.error(err.message);

        if (err.response != null && err.response.data != null)
            console.log(err.response.data);

        return;
    }
}

export async function getCheckClaims(token) {
    const realToken = token;

    try {
        const instance = axios.create({
            baseURL: ConfigurationManager.getApiUrl(),
            timeout: 15000,
            headers: { 'Authorization': `Bearer ${realToken}` }
        });

        const res = await instance.get('/debugInfo/Claims');

        return res.data;
    } catch (err) {
        console.error(err.message);

        if (err.response != null && err.response.data != null)
            console.log(err.response.data);

        return;
    }
}


export async function getAllChats(token, persona) {
    const realToken = token;

    try {
        const instance = axios.create({
            baseURL: ConfigurationManager.getApiUrl(),
            timeout: 15000,
            headers: { 'Authorization': `Bearer ${realToken}` }
        });

        const res = await instance.get(`/chat/GetAllChats?persona=${encodeURIComponent(persona)}`);

        return res.data;
    } catch (err) {
        console.error(err.message);

        if (err.response != null && err.response.data != null)
            console.log(err.response.data);

        return;
    }
}

export async function getConversation(token, chatId, persona) {
    const realToken = token;

    try {
        const instance = axios.create({
            baseURL: ConfigurationManager.getApiUrl(),
            timeout: 15000,
            headers: { 'Authorization': `Bearer ${realToken}` }
        });

        const res = await instance.get(`/chat/GetExistingConversation?chatId=${chatId}&persona=${encodeURIComponent(persona)}`);
        
        return res.data;
    } catch (err) {
        console.error(err.message);

        if (err.response != null && err.response.data != null)
            console.log(err.response.data);

        return;
    }
}

export async function getPersonaDetails(aadToken, personaname) {

    try {
        const instance = axios.create({
            baseURL: ConfigurationManager.getApiUrl(),
            timeout: 15000,
            headers: { 'Authorization': `Bearer ${aadToken}` }
        });

        var url = `/persona/get?name=${encodeURIComponent(personaname)}`;
        const res = await instance.get(url);

        return (res.data)
    } catch (err) {
        console.error(err.message);

        if (err.response != null && err.response.data != null)
            console.log(err.response.data);

        return;
    }
}

export async function getInferenceOptions(aadToken) {

    try {
        const instance = axios.create({
            baseURL: ConfigurationManager.getApiUrl(),
            timeout: 15000,
            headers: { 'Authorization': `Bearer ${aadToken}` }
        });

        var url = `/Inference/get`;
        const res = await instance.get(url);

        return (res.data)
    } catch (err) {
        console.error(err.message);

        if (err.response != null && err.response.data != null)
            console.log(err.response.data);

        return;
    }
}

export async function triggerImportOfBlobDataFile(aadToken, personaName, searchAgentName) {

    try {
        const instance = axios.create({
            baseURL: ConfigurationManager.getApiUrl(),
            timeout: 15000,
            headers: { 'Authorization': `Bearer ${aadToken}` }
        });

        var url = `/SearchIndex/ImportBlobDataFile?persona=${encodeURIComponent(personaName)}&searchAgentName=${encodeURIComponent(searchAgentName)}`;
        await instance.get(url);
    } catch (err) {
        console.error(err.message);

        if (err.response != null && err.response.data != null)
            console.log(err.response.data);

        return;
    }
}


export async function getInferenceChat(token, debugLogId) {
    const realToken = token;

    try {
        const instance = axios.create({
            baseURL: ConfigurationManager.getApiUrl(),
            timeout: 15000,
            headers: { 'Authorization': `Bearer ${realToken}` }
        });

        const res = await instance.get(`/DebugInfo/Inference?id=${debugLogId}`);

        return res.data;
    } catch (err) {
        console.error(err.message);

        if (err.response != null && err.response.data != null)
            console.log(err.response.data);

        return;
    }
}